import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import path from 'common/constants/path';
import useAuthProvider from 'store/authProvider/useAuthProvider';
import { commonRoles, commonStatusTypes } from 'common/constants/enums';
import AutocompleteInput from 'common/components/simpleInputs/AutocompleteInput';
import {
  getInitialTenant,
  getIsSelectorLoading,
  getOrganizationsForLogin,
} from './ducks/selectors';
import useStyles from '../entityAuthSelectorStyles';
import { organizationSelectorActions } from './ducks';

const allowedUrls = [
  path.private.dashboard,
  path.private.triages.escalated,
  path.private.triages.common,
];

const disabledForRoles = [
  commonRoles.technician,
  commonRoles.physician,
  commonRoles.ecgTech,
  commonRoles.admin,
];

const OrganizationSelector = () => {
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const loading = useSelector(getIsSelectorLoading);
  const list = useSelector(getOrganizationsForLogin);
  const initialTenant = useSelector(getInitialTenant);

  const role = useMemo(
    () => authProvider.currentUserRole,
    [authProvider.currentUserRole]
  );

  const tenantId = useMemo(
    () => authProvider.currentTenantId,
    [authProvider.currentTenantId]
  );

  const disabled = disabledForRoles.includes(role);
  const { inputWrapper, ...classes } = useStyles({ disabled });

  const value = useMemo(
    () =>
      tenantId && initialTenant?.id === tenantId
        ? { id: initialTenant?.id, name: initialTenant?.name }
        : {},
    [tenantId, initialTenant?.id, initialTenant?.name]
  );

  const filterTenants = debounce((s) => {
    if (!(s?.length >= 3) || initialTenant?.name === s) {
      return;
    }

    dispatch(
      organizationSelectorActions.filterOrganizations({
        filter: { Name: s, statuses: [commonStatusTypes.active] },
      })
    );
  }, 400);

  const onLoginSuccess = ({ payload }) => {
    localStorage.accessToken = payload.data.accessToken;
    const { origin, pathname } = document.location;

    if (allowedUrls.includes(pathname)) {
      document.location.reload();
      return;
    }

    document.location.replace(`${origin}${path.private.triages.escalated}`);
  };

  const changeTenant = (e, val) => {
    if (val.id === tenantId) {
      return;
    }

    dispatch(
      organizationSelectorActions.loginToOrganization(
        { params: { id: val.id } },
        onLoginSuccess
      )
    );
  };

  const onOpenAutocomplete = () => {
    dispatch(
      organizationSelectorActions.filterOrganizations({
        Name: null,
        filter: {
          statuses: [commonStatusTypes.active],
        },
        pagination: {
          page: 1,
          perPage: 100,
        },
      })
    );
  };

  useEffect(() => {
    if (!tenantId) {
      return;
    }

    dispatch(
      organizationSelectorActions.getSelectedOrganization({
        id: tenantId ? [tenantId] : null,
      })
    );
  }, [dispatch, tenantId]);

  useEffect(
    () => () => dispatch(organizationSelectorActions.clearTenantState()),
    [dispatch]
  );

  const optionBlock = (option) => (
    <span
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {option.name}
    </span>
  );

  return (
    <AutocompleteInput
      disableClearable
      name="tenant"
      variant="standard"
      optionLabel="name"
      optionValueName="id"
      value={value}
      options={list}
      loading={loading}
      classes={classes}
      disabled={disabled}
      className={inputWrapper}
      onChange={changeTenant}
      renderOption={optionBlock}
      onOpen={onOpenAutocomplete}
      onInputChange={(e, s) => filterTenants(s)}
    />
  );
};

export default OrganizationSelector;
